.container {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease;
}

.container.open {
  max-height: 400px;
}

