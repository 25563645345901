.summaryCard {
    // margin-top: 16px;
}

.summaryText {
    color: var(--chakra-colors-text)
}

.shimmer {
    border-radius: 4px;
    background: linear-gradient(
        90deg,
        var(--chakra-colors-gray-100) 0%,
        var(--chakra-colors-gray-200) 50%,
        var(--chakra-colors-gray-100) 100%
    );
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}
